/* eslint-disable no-underscore-dangle */
import mediaQuery from 'css-mediaquery';
import decode from 'jwt-decode';
import NextApp from 'next/app';
import Router, { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import parser from 'ua-parser-js';

/** MUI */
import CssBaseline from '@mui/material/CssBaseline';

// Glide Carousel Styles
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';

// Simplebar
import 'simplebar-react/dist/simplebar.min.css';

// NProgress Bar
import NProgress from 'nprogress'; // nprogress module
import '../styles/nprogress.css'; // styles of nprogress

import '@fontsource/nunito';
import '@fontsource/roboto-slab';
import '@fontsource/roboto-slab/300.css';
import '@fontsource/roboto-slab/500.css';
import '@fontsource/roboto-slab/600.css';
import '@fontsource/roboto-slab/700.css';
import 'components/TermsAndConditionsText/TermsAndConditionsText.css';
import '../assets/styles/globals.css';

import CookieBanner from 'components/CookieBanner';
import ErrorBoundary from 'components/Error/ErrorBoundary';

import AddressProvider from 'provider/AddressProvider';
import AuthProvider from 'provider/AuthProvider';
import BackdropProvider from 'provider/BackdropProvider';
import BrandsVisitedProvider from 'provider/BrandsVisitedProvider';
import CartProvider from 'provider/CartProvider';
import { CheckoutProvider } from 'provider/CheckoutProvider';
import ConfigsProvider from 'provider/ConfigsProvider';
import CurrencyProvider from 'provider/CurrencyProvider';
import FollowingProvider from 'provider/FollowingProvider';
import ProductsVisitedProvider from 'provider/ProductsVisitedProvider';
import ReferralsProvider from 'provider/ReferralsProvider';
import SignupPopupProvider from 'provider/SignupPopupProvider';
import ThemeProvider from 'provider/ThemeProvider';
import TopBarProvider from 'provider/TopBarProvider';
import TopMenuProvider from 'provider/TopMenuProvider';
import WishlistProvider from 'provider/WishlistProvider';

/** Utils / Consts */
import { FALLBACK_TOP_BAR, FALLBACK_TOP_MENU } from 'constants/fallback-data';
import { NEXT_PUBLIC_BFF_URL, NEXT_PUBLIC_COOKIE_DOMAIN } from 'constants/runtimeConfig';
import { getJwtFromCookie, getUserFromCookie } from 'utils/cookies';
import { log } from 'utils/functions';
import { GTMPageView } from 'utils/gtm';

import { BasicLayout } from 'layouts';
import apiFactory from 'services/api/axios';

import { JWT_COOKIE_NAME, NON_LOGGED_IN_USER_ID, UTM_COOKIE_NAME, UTM_PARAMS } from 'constants';
import SubTopMenuSSR from 'layouts/SubTopMenuSSR';
import CouponProvider from 'provider/CouponProvider';
import { useCookies } from 'react-cookie';
import { useSessionStorage } from 'react-use';
import { serverLogger } from 'utils/serverLogs.utils';
import TraceProvider from '../provider/TraceProvider';

const expires = 7 * 24 * 60 * 60 * 1000; // 7days
const COOKIE_OPTIONS = { path: '/', maxAge: expires };

const appDefaultPropTopBar = { content: '' };
const appDefaultPropPageProps = {};
const appDefaultPropUserCreditDetails = {};

const App = ({
  Component,
  pageProps = appDefaultPropPageProps,
  topMenu = undefined,
  deviceType = 'desktop',
  userDetails,
  userCreditDetails = appDefaultPropUserCreditDetails,
  topBar = appDefaultPropTopBar,
}) => {
  const Layout = Component.getLayout || BasicLayout;
  const [nonLoggedInUserId, setNonLoggedInUserId] = useSessionStorage(NON_LOGGED_IN_USER_ID, null);
  const [, setCookie, removeCookie] = useCookies(['filters', UTM_COOKIE_NAME]);
  const [jwtCookie] = useCookies([JWT_COOKIE_NAME]);
  const router = useRouter();

  // keep track filters and also remove it
  useEffect(() => {
    const tabId = new Date().getTime().toString();
    localStorage.setItem(`tab_${tabId}`, '1');

    if (typeof window !== 'undefined') {
      // Remove the tabId when the tab/window is closed
      const beforeUnloadHandler = () => {
        localStorage.removeItem(`tab_${tabId}`);
        const navigationType = window.performance.getEntriesByType('navigation')[0].type;

        // Check if there are no open tabs left
        const openTabs = Object.keys(localStorage).filter((key) => key.startsWith('tab_')).length;
        // do not remove cookie on reload
        if (openTabs === 0 && navigationType !== 'reload') {
          removeCookie('filters', { path: '/', domain: NEXT_PUBLIC_COOKIE_DOMAIN });
        }
      };

      window.addEventListener('beforeunload', beforeUnloadHandler);
    }

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jwtCookie[JWT_COOKIE_NAME] && !nonLoggedInUserId) {
      const timeStamp = Date.now();
      setNonLoggedInUserId(`GUEST_${timeStamp}`);
    }
  }, [jwtCookie]);

  // to set utm source cookie so can be captured by WP when signup completed
  useEffect(() => {
    let utmSource = null;
    const queryParams = router.query;
    UTM_PARAMS.some((param) => {
      utmSource = queryParams[param];
      return utmSource;
    });
    if (utmSource) {
      setCookie(UTM_COOKIE_NAME, utmSource, COOKIE_OPTIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Binding events.
  useEffect(() => {
    const handleRouteStart = () => {
      NProgress.start();
    };
    const handleRouteDone = () => {
      NProgress.done();
    };

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  // Initiate GTM
  useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url, userDetails);
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [userDetails]);

  useEffect(() => {
    const handleRouteChangeHsq = (url) => {
      if (window && window._hsq) {
        if (userDetails && userDetails.email) {
          const { _hsq } = window;
          _hsq.push(['identify', { email: `${userDetails.email}` }]);
          _hsq.push(['setPath', url]);
          _hsq.push(['trackPageView']);
        }
      }
    };
    Router.events.on('routeChangeComplete', handleRouteChangeHsq);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChangeHsq);
    };
  }, [userDetails]);

  const swrConfig = {
    refreshInterval: 0,
    errorRetryInterval: 1000, // error retry interval in milliseconds
    revalidateOnFocus: false, // automatically revalidate when window gets focused
    revalidateIfStale: false, // automatically revalidate even if there is stale data
    revalidateOnReconnect: false, // automatically revalidate when the browser regains a network connection (via navigator.onLine)
  };

  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === 'mobile' ? '0px' : '1024px',
    }),
  });

  const additionalThemeConfig = {
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  };

  return (
    <TraceProvider>
      <ThemeProvider additionalThemeConfig={additionalThemeConfig}>
        <BackdropProvider>
          <SignupPopupProvider>
            <ConfigsProvider>
              <ReferralsProvider>
                <SnackbarProvider preventDuplicate anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <CurrencyProvider>
                    <SWRConfig value={swrConfig}>
                      <AuthProvider userDetails={userDetails} userCreditDetails={userCreditDetails}>
                        <AddressProvider>
                          <CartProvider>
                            <CheckoutProvider>
                              <WishlistProvider>
                                <ProductsVisitedProvider>
                                  <BrandsVisitedProvider>
                                    <FollowingProvider>
                                      <CouponProvider>
                                        <TopMenuProvider data={topMenu.data} error={topMenu.error}>
                                          <TopBarProvider data={topBar}>
                                            <CssBaseline />
                                            <Layout>
                                              <ErrorBoundary>
                                                <Component {...pageProps} />
                                                {/* TODO: SubTopMenuSSR is causing Hydration error which affects client performance. We have to look into this */}
                                                <SubTopMenuSSR />
                                              </ErrorBoundary>
                                            </Layout>
                                          </TopBarProvider>
                                        </TopMenuProvider>
                                        <CookieBanner />
                                      </CouponProvider>
                                    </FollowingProvider>
                                  </BrandsVisitedProvider>
                                </ProductsVisitedProvider>
                              </WishlistProvider>
                            </CheckoutProvider>
                          </CartProvider>
                        </AddressProvider>
                      </AuthProvider>
                    </SWRConfig>
                  </CurrencyProvider>
                </SnackbarProvider>
              </ReferralsProvider>
            </ConfigsProvider>
          </SignupPopupProvider>
        </BackdropProvider>
      </ThemeProvider>
    </TraceProvider>
  );
};

App.getInitialProps = async (context) => {
  let deviceType;
  let topMenuResponse;
  let newUserDetails;
  let oldUserDetails;
  let jwtToken;
  let userId;
  let newUserCreditDetails;
  let topBarResponse;

  if (context.ctx.req) {
    if (context.ctx.req.cookies) {
      oldUserDetails = getUserFromCookie(context.ctx.req);
      jwtToken = getJwtFromCookie(context.ctx.req);
    }

    deviceType = parser(context.ctx.req.headers['user-agent']).device.type || 'desktop';
  }

  try {
    if (jwtToken && jwtToken !== 'GUEST') {
      // for logged out users getJwtFromCookie() returns 'GUEST'
      const decodedJwt = decode(jwtToken);
      userId = decodedJwt.data.id;

      newUserDetails = (
        await apiFactory({
          baseURL: NEXT_PUBLIC_BFF_URL,
          headers: { Authorization: jwtToken },
        }).get(`/users/${userId}`)
      ).data;

      // Retrieve credit limit and balance
      if (
        !context.ctx.req.cookies.user_credit ||
        Object.keys(JSON.parse(context.ctx.req.cookies.user_credit || '{}')).length === 0
      ) {
        newUserCreditDetails = (
          await apiFactory({
            baseURL: NEXT_PUBLIC_BFF_URL,
            headers: { Authorization: jwtToken },
            params: { includeBalance: 'true' },
          }).get(`/users/${userId}/credit`)
        ).data;
      } else {
        newUserCreditDetails = JSON.parse(context.ctx.req.cookies.user_credit);
      }

      // Put user's overdue amount into the userCreditDetails object
      if (!JSON.parse(context.ctx.req.cookies.user_credit || '{}')?.overdueAmount) {
        let overdueData;
        try {
          const {
            data: { data: overdueDataResponse },
          } = await apiFactory({
            baseURL: NEXT_PUBLIC_BFF_URL,
            headers: { Authorization: jwtToken },
            params: { includeBalance: 'true' },
          }).get(`/users/${userId}/overdueAmount`);
          overdueData = overdueDataResponse;
        } catch (error) {
          serverLogger.error(
            `Failed to get overdue amount (SSR) for retailerId: ${userId}`,
            { context: { error, userId } },
            'checkout',
            'server'
          );
        }
        newUserCreditDetails = { ...newUserCreditDetails, overdueAmount: parseFloat(overdueData?.overdueAmount ?? 1) };
      }
    }

    topBarResponse = (
      await apiFactory({
        baseURL: NEXT_PUBLIC_BFF_URL,
      }).get('/top-bar')
    ).data;

    topMenuResponse = (
      await apiFactory({
        baseURL: NEXT_PUBLIC_BFF_URL,
      }).get('/top-menu')
    ).data;
  } catch (err) {
    log.error('App getInitialProps ERROR: ', err);

    return {
      ...NextApp.getInitialProps(context),
      topBar: FALLBACK_TOP_BAR,
      topMenu: {
        error: err,
        data: FALLBACK_TOP_MENU,
      },
      deviceType,
      userId,
      jwtToken,
      userDetails: oldUserDetails,
      userCreditDetails: newUserCreditDetails,
    };
  }

  return {
    ...NextApp.getInitialProps(context),
    topBar: {
      content: topBarResponse.content,
    },
    topMenu: {
      data: topMenuResponse,
    },
    deviceType,
    userDetails: newUserDetails,
    userCreditDetails: newUserCreditDetails,
    userId,
    jwtToken,
  };
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.shape({}),
  topBar: PropTypes.shape({ content: PropTypes.string }),
  topMenu: PropTypes.shape({ data: PropTypes.arrayOf(PropTypes.shape({})), error: PropTypes.shape({}) }),
  deviceType: PropTypes.string,
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    shippingCountry: PropTypes.string,
    paymentDays: PropTypes.number,
    creditLimit: PropTypes.number,
  }).isRequired,
  userCreditDetails: PropTypes.shape({
    creditLimit: PropTypes.number,
    creditBalance: PropTypes.number,
    upfrontPaymentPercentage: PropTypes.number,
    paymentDays: PropTypes.number,
  }),
};

export default App;
